import React from "react";
import { Link, useParams } from "react-router-dom";
import crops_design from '../images/crops_design.jpg';
import crops_wireframe from '../images/crops_wireframe.jpg';
import crops_code from '../images/crops_code.jpg';
import home_crops from '../images/home_crops.jpg';
import fitness_design from '../images/fitness_design.jpg';
import fitness_tracker from '../images/fitness_tracker.jpg';
import fitness_code from '../images/fitness_code.jpg';
import fitness_wireframe from '../images/fitness_wireframe.jpg';
import website_code from '../images/website_code.jpg';
import website_design from '../images/website_design.jpg';
import website_wireframe from '../images/website_wireframe.jpg';
import my_website from '../images/my_website.jpg';
import xyz_code from '../images/xyz_code.jpg';
import xyz_design from '../images/xyz_design.jpg';
import xyz_wireframe from '../images/xyz_wireframe.jpg';
import xyz_motors from '../images/xyz_motors.jpg';




const ProjectPage = () => {

    const {projectid} = useParams();
    let currentProject = {};

    if (projectid === "pixel_runner") {
            currentProject = {   
            'name': 'pixel_runner',
            'picture_1': my_website,
            'picture_2': website_wireframe,
            'picture_3': website_design,
            'picture_4': website_code,
            'description_1': 'This is my main website. This project had 2 goals: A- present infromation about me and my work in as simple and clear way as possible & B- try and test as many aspect of both design and development as possible. In other words: make mistakes and learn from them.',
            'description_2': 'All starts with drawing out ideas- all needed data, the flow of website, how to navigate, what and how to present, UI elements etc. ',
            'description_3': 'For design purpose I use Figma software. All parts: background pictures, buttons, UI icons, animations etc are designed from scratch. Then assembled to present what final product should look like. Finally design is translated to different devices, like mobile phones.',
            'description_4': 'Final part of process is to develop the website. Here again every line of code is written from scratch to ensure full controll of what goes in.  This website will be my testing ground for all development aspects of production, before I apply them elsewhere. '
        }
    } else if (projectid === "xyz_motorcycles") {
            currentProject = {
            'name': 'xyz_motorcycles',
            'picture_1': xyz_motors,
            'picture_2': xyz_wireframe,
            'picture_3': xyz_design,
            'picture_4': xyz_code,
            'description_1': 'Website made for a small, custom-made motorcycle company. Goal of this website was to present full offer, while not making it too business centered.',
            'description_2': "Initial design process focused on looking for ways to present company's message and getting the right vibe. What makes this brand what it is, why people want to buy their bikes and how do we translate that to digital form.",
            'description_3': 'It was important to client to make sure that potential buyer can get realistic estimate on how customization will affect final price. UX and UI design had to make it easy for visitors to get all information they need without having to click through bunch of links.',
            'description_4': 'Coding was very straightforward, I implemented all common solutions that are well tested. Biggest chalenge was to make this react website friendly to google search engine.'
        }
    } else if (projectid === "fitness_tracker") {
            currentProject = {
            'name': 'fitness_tracker',
            'picture_1': fitness_tracker,
            'picture_2': fitness_wireframe,
            'picture_3': fitness_design,
            'picture_4': fitness_code,
            'description_1': 'For this project client wished for fully personalized tracking web app. Main goals were to track users input about their training, nutrition and sleep. This app required a lot of back and forth in order to get the right customization level.',
            'description_2': "There's a lot of moving parts and input fields. Designing took a lot of careful work to avoid cluttering screen. I prepared multiple versions of possible UI, then eliminated them one by one, till we were left with the final version.",
            'description_3': 'Working with designing tools like Figma speeds up overall process a lot. It also allows me to share design files with my client, in order to get more frequent and precise feedback.',
            'description_4': "Fitness app required connecting to database. For this I used Google's cloud Firebase. This allowed users to have their data stored and available whenever needed."
        }
    } else if (projectid === "home_crops") {
            currentProject = {
            'name': 'home_crops',
            'picture_1': home_crops,
            'picture_2': crops_wireframe,
            'picture_3': crops_design,
            'picture_4': crops_code,
            'description_1': 'Home crops is a temporary name for an app that will help you grow your plants and share them with other app users. This is my side project and something I hope to release one day.',
            'description_2': "The goal of this project is to help connect people, who want to grow their own plants. Seems simple, but scope of this project is much wider, that it looks like. Since it's a mobile app and a web app, initial design was done on project cards simultaneously, to ensure same experience.",
            'description_3': 'Since this is both a task assisting app and a resource sharing platform, biggest chalenge was figuring out what part of design takes priority. By using reusable cards I could create a fluent flow between different functional part of this app.',
            'description_4': "Development required use of wide variety of tools, which slowed down the testing process. Sometimes it's an unfortunate trade off, if we want to make sure best possible end experience."
        }
    }

    return (
        <main className="project_box">
            <section className="project_close-box">
                <Link className="project_close" to="/portfolio"></Link>
            </section>
            <h1 className="project_title">{currentProject.name}</h1>
            <section className="project_description-right">
                <section className="projext_partial-picture">
                    <img className="project_picture" src={currentProject.picture_1}></img>
                </section>
                <section className="project_partial-text">
                    <p className="project_text">{currentProject.description_1}</p>
                </section>
            </section>
            <section className="project_description-left">
                <section className="project_partial-text">
                    <p className="project_text">{currentProject.description_2}</p>
                </section>
                <section className="projext_partial-picture">
                    <img className="project_picture" src={currentProject.picture_2}></img>
                </section>
            </section>
            <section className="project_description-right">
                <section className="projext_partial-picture">
                    <img className="project_picture" src={currentProject.picture_3}></img>
                </section>
                <section className="project_partial-text">
                    <p className="project_text">{currentProject.description_3}</p>
                </section>
            </section>
            <section className="project_description-left">
                <section className="project_partial-text">
                    <p className="project_text">{currentProject.description_4}</p>
                </section>
                <section className="projext_partial-picture">
                    <img className="project_picture" src={currentProject.picture_4}></img>
                </section>
            </section>
        </main>
    );
}

export default ProjectPage;