import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LandingPage from '../components/LandingPage';
import AboutPage from '../components/AboutPage';
import ContactPage from '../components/ContactPage';
import PortfolioPage from '../components/PortfolioPage';
import ProjectPage from '../components/ProjectPage';
import NotFound from '../components/NotFound';
import ScrollToTop from '../components/ScrollToTop';

const AppRouter = () => {
    return (
    <BrowserRouter>
        <ScrollToTop />
        <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/portfolio" element={<PortfolioPage />} />
            <Route path="/portfolio/:projectid" element={<ProjectPage />}/>
            <Route path="*" element={<NotFound/>}  />
        </Routes>
    </BrowserRouter>
    )
};

export default AppRouter;