import React from 'react';
import { Link } from 'react-router-dom';
import Navigation from './Navigation';
import profile_picture from '../images/profile_picture.jpg';
import link_A from '../images/button_link_A.svg';
import link_B from '../images/button_link_B.svg';

const AboutPage = () => {
    return (
        <main className='about_box'>
            <Navigation />
            <section className='about_preload'>
                <img src={link_A}></img>
                <img src={link_B}></img>
            </section>
            <section className='about_left'>
                <h1 className='about_name'>Few words <span className='about_name-break'><br/></span> about me</h1>
                <img src={profile_picture} className='profile_picture'></img>
            </section>
            <hr className='about_line'></hr>
            <section className='about_right'>
                <section className='about_text'>
                    <p>Hello! My name is Jan and I’m a designer who codes.</p><br/>
                    <p>I strive to be creative in everything I do, whether it’s a simple website, an app or anything outside of my professional life.</p><br/>
                    <p>I’m passionate about music (I used to play drums!), drawing illustrations, cooking and playing board games with my friends.</p><br/>
                    <p>I hope that my work can help others realize their own passions.</p><br/>
                    <p>Feel free to say hello and let’s build something together!</p>
                </section>
                <section className='about_navigation'>
                    <Link className='about-link_B' to="/contact">
                        <p>contact</p>
                    </Link>
                    <Link className='about-link_A' to="/portfolio">
                        <p>my work</p>
                    </Link>
                </section>
            </section>
        </main>
    );
};

export default AboutPage;