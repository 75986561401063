import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
// import { useParams } from 'react-router-dom';
import Navigation from './Navigation';
import home_crops from '../images/home_crops.jpg';
import fitness_tracker from '../images/fitness_tracker.jpg';
import my_website from '../images/my_website.jpg';
import xyz_motors from '../images/xyz_motors.jpg';
import link_A from '../images/button_link_A.svg';
import link_B from '../images/button_link_B.svg';
import next from '../images/button_next.svg';
import previous from '../images/button_previous.svg';


const PortfolioPage = () => {
    const projects = [
        {   
            'name': 'pixel_runner',
            'background': my_website,
            'role': 'design and development',
            'description': 'my website'
        }, {
            'name': 'xyz_motorcycles',
            'background': xyz_motors,
            'role': 'design and development',
            'description': 'website for a small \nmotorcycle company'
        }, {
            'name': 'fitness_tracker',
            'background': fitness_tracker,
            'role': 'redesign and development',
            'description': 'web app for customizing and tracking\nyour training and diet'
        }, {
            'name': 'home_crops',
            'background': home_crops,
            'role': 'design and development',
            'description': 'mobile and web app for sharing\nhomegrown plants and seeds'
        }
    ]

    const [count, setCount] = useState(() => {
        return 0;
    });

    const nextProject = () => {
        setCount((prevCount)=> {
            if (prevCount < projects.length - 1) {
                return prevCount + 1
            } else {
               return prevCount = 0
            }
        })
    }

    const previousProject = () => {
        setCount((prevCount)=> {
            if (prevCount > 0) {
                return prevCount - 1
            } else {
               return prevCount = projects.length - 1
            }
        })
    }
    
    return (
        <main className='portfolio_box'>
            <Navigation />
            <section className='portfolio_preload'>
                <img src={link_A}></img>
                <img src={link_B}></img>
                <img src={previous}></img>
                <img src={next}></img>
            </section>
            <section className='portfolio_description'>
                <h1 className='portfolio_name'>Some of my work</h1>
                <h2 className='portfolio_subtitle'>All work starts at the drawing board with a pen and paper...<br/>...then it's just a matter of 
                picking the right tools</h2>
                <h3 className='portfolio_skill_list_name'>What can I do for you?</h3>
                <section className='portfolio_list'>
                    <ul>
                        <li>-Design a website</li>
                        <li>-Design UX/UI</li>
                        <li>-Code a website</li>
                        <li>-Code an app/ web app</li>
                    </ul>
                    <ul>
                        <li>-Help you figure <br/>out what you need</li>
                    </ul>
                </section>
            </section>
            <section className='portfolio_presentation'>
                <NavLink to={`/portfolio/${projects[count].name}`}>
                    <div className='portfolio_presentation-picture-box'>
                        <img src={projects[count].background} className='portfolio_presentation-picture'></img>
                    </div>
                </NavLink>
                <section className='portfolio_presentation-description'>
                    <p className='portfolio_presentation-description_role'>{projects[count].role}</p>
                    <div className='portfolio_presentation-description_bottom'>
                        <p className='portfolio_presentation-description_name'>{projects[count].name}</p>
                        <p className='portfolio_presentation-description_description'>{projects[count].description}</p>
                    </div>
                </section>
                <section className='portfolio_presentation-buttons'>
                    <button className='portfolio_presentation-buttons_previous' onClick={previousProject}></button>
                    <button className='portfolio_presentation-buttons_next' onClick={nextProject}></button>
                </section>
            </section>
            <section className='portfolio_navigation'>
                <Link className='portfolio-link_B' to="/contact">
                    <p>contact</p>
                </Link>
                <Link className='portfolio-link_A' to="/about">
                    <p>about me</p>
                </Link>
            </section>
        </main>  
    );
};

export default PortfolioPage;