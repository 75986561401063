import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
    return (
        <main className='notFound_box'>
            <h1 className='notFound_text'>404!</h1>
            <Link className='notFound_link' to="/">go home</Link>
        </main>
    );
};

export default NotFound;