import React from 'react';
import { Link } from 'react-router-dom';
import Navigation from './Navigation';
import link_A from '../images/button_link_A.svg';
import link_B from '../images/button_link_B.svg';

const ContactPage = () => {
    return (
        <main className='contact_box'>
            <Navigation />
            <section className='contact_resources'>
                <img src={link_A}></img>
                <img src={link_B}></img>
            </section>
            <section className='contact_content'>
                <section className='contact_intro'>
                    <h1 className='contact_name'>Let's build something together</h1>
                    <h2 className='contact_description'>Have an idea? Don't know where to start? Let me help you<br/><br/>You can reach me here &raquo;</h2>
                </section>
                <hr className='contact_line'></hr>
                <section className='contact_links'>
                    <a className='contact_email' href="mailto:jan.nowicki@pxlrnnr.com">jan.nowicki@pxlrnnr.com</a>
                    <a className='contact_links-link' target='_blank' href='https://github.com/pxlrnnr'>github</a>
                    <a className='contact_links-link' target='_blank' href='https://www.behance.net/pxl_rnnr'>behance</a>
                    <a className='contact_links-link' target='_blank' href='https://dribbble.com/pxl_rnnr'>dribbble</a>
                    <a className='contact_links-link' target='_blank' href='https://www.instagram.com/pxl_rnnr/'>instagram</a>
                </section>
            </section>
            <section className='contact_navigation'>
                <Link className='contact-link_B' to="/about">
                    <p>about me</p>
                </Link>
                <Link className='contact-link_A' to="/portfolio">
                    <p>my work</p>
                </Link>
            </section>
        </main>
    );
};

export default ContactPage;