import React from 'react';
import { Link } from 'react-router-dom';
import Navigation from './Navigation';
import background from '../images/background.png';
import mobile_background from '../images/mobile_background.png';
import link_A from '../images/button_link_A.svg';
import link_B from '../images/button_link_B.svg';

const LandingPage = () => {
    return (
        <main className='landing_box'>
            <Navigation />
            <section className='landing_resources'>
                <img src={background}></img>
                <img src={mobile_background}></img>
                <img src={link_A}></img>
                <img src={link_B}></img>
            </section>
            <section className='left_section'>
                <h1 className='title'>pixel_<span className='title_break'><br/></span>runner...</h1>
                <h2 className='subtitle'>web design and development<br/>built pixel by pixel</h2>
            </section>
            <section className='right_section'>
                <Link to="/portfolio" className='link_B'>
                    <p>my work</p>
                </Link>
                <Link to="/contact" className='link_A'>
                    <p>contact</p>
                </Link>
            </section>
        </main>
    );
};

export default LandingPage;