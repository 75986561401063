import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import logo from '../images/logo.svg';
import menu from '../images/menu.svg';
import button_return from '../images/button_return.svg';
import github from '../images/github_icon.svg';
import behance from '../images/behance_icon.svg';
import dribbble from '../images/dribbble_icon.svg';
import instagram from '../images/instagram_icon.svg';

const Navigation = () => {

    const [isActive, setActive] = useState('false');

    const toggleMenu = () => {
        setActive(!isActive);
    }

    return (
        <section className='navigation_box'>
            <section className='navigation_preload'>
                <img src={logo}></img>
                <img src={menu}></img>
                <img src={button_return}></img>
                <img src={github}></img>
                <img src={behance}></img>
                <img src={dribbble}></img>
                <img src={instagram}></img>
            </section>
            <section className='navigation_icons'>
                <Link className='navigation_home' to='/'></Link>
                <button className='navigation_menu' onClick={toggleMenu}></button>
            </section>
            <section className={isActive ? 'navigation_navigation-off' : 'navigation_navigation-on'}>
                <section className='navigation_close-menu-box'>
                    <button className='navigation_close-menu' onClick={toggleMenu}></button>
                </section>
                <section className='navigation_content'>
                    <section className='navigation_local'>
                        <Link className='navigation_local-link' to="/" >home</Link>
                        <Link className='navigation_local-link' to="/about">about</Link>
                        <Link className='navigation_local-link' to="/portfolio">works</Link>
                        <Link className='navigation_local-link' to="/contact">contact</Link>
                    </section>
                    <hr className='navigation_line'></hr>
                    <section className='navigation_social'>
                        <a className='navigation_email' href='mailto:jan.nowicki@pxlrnnr.com'>jan.nowicki@pxlrnnr.com</a>
                        <div className='navigation_social-icons'>
                            <a className='navigation_github' target="_blank" href='https://github.com/pxlrnnr'></a>
                            <a className='navigation_behance' target="_blank" href='https://www.behance.net/pxl_rnnr'></a>
                            <a className='navigation_dribbble' target="_blank" href='https://dribbble.com/pxl_rnnr'></a>
                            <a className='navigation_instagram' target="_blank" href='https://www.instagram.com/pxl_rnnr/'></a>
                        </div>
                    </section>
                </section>
            </section>
        </section>
    );
};

export default Navigation;